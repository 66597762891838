.Hero {
  user-select: none;
  color: #ffffff;
  text-align: center;
  height: 100%;
  display: grid;
  grid-template-rows: 25% 50% 25%;
  padding: 64px 16px;
  place-items: center;

  .imgContainer {
    height: 100%;
    img {
      pointer-events: none;
      user-select: none;
      animation: slidein 5s infinite;
      animation-timing-function: ease-in-out;
      height: 100%;
    }
  }

  .social {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;
    width: 100%;

    img {
      max-height: 300px;
    }

    img:hover {
      cursor: pointer;
      animation-name: grow;
      animation-duration: 0.25s;
      animation-timing-function: ease-in-out;
    }
  }

  @media only screen and (max-width: 500px) {
    .imgContainer {
      img {
        width: 80%;
      }
    }
  }
}

@keyframes slidein {
  0% {
    transform: translateY(-10px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes grow {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0);
  }
}
