.Project_reverse {
  max-width: 1000px;
  margin: 48px auto 0 auto;
  display: grid;
  grid-template-columns: 400px 700px;
  margin-bottom: 128px;

  img {
    width: 100%;
    min-height: 100%;
    border-radius: 5px;
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.2);
    position: relative;
    left: -100px;
    object-fit: cover;
  }
  .desc {
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-self: flex-end;
    .title {
      padding-top: 16px;
      height: 100%;
      padding-right: 116px;
      h3,
      h4 {
        text-align: right;
      }

      h3 {
        margin-bottom: 8px;
        font-weight: 400;
        text-align: center;
      }
    }

    .technologies {
      display: flex;
      gap: 16px;
      padding-left: 16px;
    }

    .text {
      background-color: #10101a;
      border-radius: 5px;
      margin-top: 16px;
      padding: 16px;
      box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.2);
    }
  }
}

@media only screen and (max-width: 1200px) {
  .Project_reverse {
    display: flex;
    flex-direction: column-reverse;

    img {
      width: 100%;
      max-height: 100%;
      left: 0;
    }

    .desc {
      right: 0;

      .title {
        padding: 0;
        padding-top: 16px;
      }

      .technologies {
        padding: 0;
        justify-content: center;
      }
    }
  }
}
