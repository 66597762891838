@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif;
}

html {
  background-color: #10101a;
}

html,
body,
#root {
  height: 100%;
}

h1,
h2,
h3,
h4 {
  margin-bottom: 16px;
  line-height: 1.5;
}

h2,
h3,
h4 {
  font-weight: 200;
}

p {
  line-height: 1.5;
}
