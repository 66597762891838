.Contact {
  color: #ffffff;
  padding: 64px 16px;
  max-width: 1000px;
  margin: 0 auto;

  h2 {
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 500px;
    margin: 0 auto;

    input {
      height: 40px;
    }

    input,
    textarea {
      background-color: #322e3d;
      border: none;
      color: #ffffff;
      padding: 8px;
      font-size: 1rem;
      border-radius: 5px;
      margin-bottom: 16px;

      &:focus {
        outline: solid #e7b9d4;
      }
    }

    textarea {
      resize: none;
    }

    #submit {
      cursor: pointer;
      background-color: #e7b9d4;
      color: #10101a;
      font-weight: 600;
    }
  }
}
