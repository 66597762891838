.AboutMe {
  color: #ffffff;
  padding: 64px 16px;
  max-width: 1000px;
  margin: 0 auto;

  h2 {
    text-align: center;
  }

  .container {
    margin-top: 48px;
    display: flex;
    gap: 32px;
    align-items: center;

    .imgContainer {
      max-width: 100%;
      aspect-ratio: 1;
      border-radius: 100%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}

@media only screen and (max-width: 850px) {
  .AboutMe {
    .container {
      flex-direction: column;
    }
  }
}
